<template>
    <v-row style="min-height: 15vh">
        <v-col align="right" style="align-items:flex-end; padding: 2px !important; padding-right: 20px !important;">
            <router-link v-if="language=='ru'" to="dairy" style="color:#89b0ae; font-size: small; font-weight: bolder">
                МОЙ ДНЕВНИК
            </router-link>
            <router-link v-else to="dairy" style="color:#89b0ae; font-size: small; font-weight: bolder">VIEW
                DIARY
            </router-link>
        </v-col>
        <v-col cols="12" style="padding-bottom: 0px; margin-bottom: 0px;">
            <mood-line :value="moodLineData" :gradient="moodLineGradient"></mood-line>
        </v-col>
        <v-col align="left">
                                    <span v-if="language=='ru'" class="light-background colored-text"
                                          style="text-align: right !important; alignment: right; font-size: x-small"
                                    >
                            ТРЕНД НАСТРОЕНИЯ
                        </span>
                        <span v-else class="light-background colored-text"
                              style="text-align: right !important; alignment: right; font-size: x-small"
                        >
                            MOOD TREND
                        </span>
        </v-col>
        <v-col align="right">
            <v-icon v-for=" icon in moodIcons" :key="icon.key" small :color="icon.color">{{icon.key}}
            </v-icon>
        </v-col>
    </v-row>
</template>

<script>
    import MoodLine from "../components/MoodLine";
    import {COLORS} from "../main";

    export default {
        name: "MoodLineBlock",
        components: {
            MoodLine,
        },
        props:["moodLineData", "language"],
        watch: {
            moodLineData: {
                immediate: true,
                handler () {
                    this.setMoodData()
                }
            }
        },
        data(){
            return{
                moodIcons: [
                    {key: "mdi-emoticon-sad-outline", color: COLORS.TERRIBLE},
                    {key: "mdi-emoticon-confused-outline", color: COLORS.BAD},
                    {key: "mdi-emoticon-neutral-outline", color: COLORS.FINE},
                    {key: "mdi-emoticon-happy-outline", color: COLORS.GOOD},
                    {key: "mdi-emoticon-outline", color: COLORS.GREAT}
                ],
                moodLineGradients: [COLORS.TERRIBLE, COLORS.BAD, COLORS.FINE, COLORS.GOOD, COLORS.GREAT],
                moodLineGradient: [],
            }
        },
        mounted() {
            this.setMoodData()
        },
        methods: {
            setMoodData() {
                if (this.moodLineData.length > 0) {
                    let moodMax = (Math.max(...this.moodLineData) | 0)
                    let moodMin = (Math.min(...this.moodLineData) | 0)
                    this.moodLineGradient = []
                    for (let i = moodMax; i >= moodMin; i--) {
                        this.moodLineGradient.push(this.moodLineGradients[i - 1])
                    }
                }
            },
        }
    }
</script>

<style scoped>

</style>