<template>
    <v-container fluid style="min-height: 90vh" :class="[{ 'desktop': screenWidth>600 }]">
        <v-row class="normal-text" v-if="loading==false">
            <v-col
                    cols="12"
                    :class="[{ 'no-padding': screenWidth<600 }]"
            >
                <v-row>
                    <v-col cols="12" :class="['no-padding']">
                        <filter-tabs
                                @clicked="selectTimeTab"
                                :selected-tab="selectedTimeTab"
                                :tab-color="colorDark"
                                :screen-width="screenWidth"
                                :tabs="language=='ru'? timeTabs_ru:timeTabs">
                        </filter-tabs>
                    </v-col>
                </v-row>

                <v-row v-if="userDataSelectedByTime.length==0" align-content="center" class="light-background">
                    <v-col cols="12" class="no-padding">
                        <no-data-info :language="language" :user-not-found="userNotFound"></no-data-info>
                    </v-col>
                </v-row>

                <v-row v-if="userDataSelectedByTime.length!=0">
                    <v-col cols="12">
                        <mood-line-block :mood-line-data="moodLineData" :language="language"></mood-line-block>
                    </v-col>
                </v-row>

                <v-row v-if="userDataSelectedByTime.length!=0">
                    <v-col cols="12" class="no-padding">
                        <filter-tabs
                                @clicked="selectMoodTab"
                                :selected-tab="selectedMoodTab"
                                :tab-color="colorDark"
                                :screen-width="screenWidth"
                                :tabs="language=='ru'? moodTabs_ru: moodTabs">
                        </filter-tabs>
                    </v-col>
                </v-row>

                <v-row v-if="userDataSelectedByTime.length!=0" style="align-items:center; justify-content: center;">
                    <v-col cols="5" class="d-flex" align="left"
                           style="align-items:flex-start; padding: 2px !important; padding-left: 15px !important;">
                        <v-checkbox
                                style="margin: 0px !important"
                                v-model="colorWords"
                                :color="colorDark"
                                hide-details

                        ></v-checkbox>
                        <span v-if="language=='ru'" class="light-background colored-text"
                              style="text-align: left !important;  font-size: x-small; padding: 2px; padding-top: 9px !important;"
                        >
                            ЦВЕТ
                        </span>
                        <span v-else class="light-background colored-text"
                              style="text-align: left !important;  font-size: x-small; padding: 2px; padding-top: 9px !important;"
                        >
                            COLOR
                        </span>


                    </v-col>
                    <spacer></spacer>

                    <v-col align="right"
                           style="align-items:flex-end; padding: 2px !important; padding-right: 20px !important;">

                        <span v-if="language=='ru'" class="light-background colored-text"
                              style="font-size: x-small; padding: 2px; padding-top: 9px !important;"
                        >
                            НАЖМИ НА ЛЮБОЕ СЛОВО
                        </span>

                        <span v-else class="light-background colored-text"
                              style="font-size: x-small; padding: 2px; padding-top: 9px !important;"
                        >
                            CLICK ON A WORD TO FILTER
                        </span>
                    </v-col>


                </v-row>

                <word-cloud-block v-if="userDataSelectedByTime.length!=0"
                                  :color-words="colorWords"
                                  :color-dark="colorDark"
                                  :c-max="reasonGroupsMax"
                                  :concept-data="reasonGroupData"
                                  :selected-concept="selectedReasonGroup"
                                  :concept-title="language=='ru'? plots_ru[3].value: plots[3].value"
                                  :divider="true"
                                  v-on:select-concept="selectReasonGroup"
                                  v-on:de-select-concept="deselectReasonGroup"
                >
                </word-cloud-block>

                <word-cloud-block v-if="userDataSelectedByTime.length!=0"
                                  :color-words="colorWords"
                                  :color-dark="colorDark"
                                  :c-max="emotionsMax"
                                  :concept-data="emotionData"
                                  :selected-concept="selectedEmotion"
                                  :concept-title="language=='ru'? plots_ru[0].value: plots[0].value"
                                  :divider="true"
                                  v-on:select-concept="selectEmotion"
                                  v-on:de-select-concept="deselectEmotion"
                >
                </word-cloud-block>

                <word-cloud-block v-if="userDataSelectedByTime.length!=0"
                                  :color-words="colorWords"
                                  :color-dark="colorDark"
                                  :c-max="feelingMax"
                                  :concept-data="feelingData"
                                  :selected-concept="selectedFeeling"
                                  :concept-title="language=='ru'? plots_ru[1].value: plots[1].value"
                                  :divider="true"
                                  v-on:select-concept="selectFeeling"
                                  v-on:de-select-concept="deselectFeeling"
                >
                </word-cloud-block>

                <word-cloud-block v-if="userDataSelectedByTime.length!=0"
                                  :color-words="colorWords"
                                  :color-dark="colorDark"
                                  :c-max="bodyMax"
                                  :concept-data="bodyData"
                                  :selected-concept="selectedBody"
                                  :concept-title="language=='ru'? plots_ru[2].value: plots[2].value"
                                  :divider="false"
                                  v-on:select-concept="selectBody"
                                  v-on:de-select-concept="deselectBody"
                >
                </word-cloud-block>

            </v-col>
        </v-row>
    </v-container>


</template>


<script>
    import MoodLineBlock from "../components/MoodLineBlock";
    import FilterTabs from "../components/FilterTabs";
    import WordCloudBlock from "../components/WordCloudBlock";
    import NoDataInfo from "../components/NoDataInfo";
    import {GraphqlService} from "../services/graphql";
    import {COLORS} from "../main";

    export default {
        name: "Home",
        components: {
            FilterTabs,
            MoodLineBlock,
            WordCloudBlock,
            NoDataInfo
        },
        props: {
            id: {
                type: String,
                default: null
            },
        },
        provide: {},
        data() {
            return {
                language: "en",
                userNotFound: false,
                colorWords: true,
                emotionOverlay: false,
                reasonGroupsOverlay: false,
                loading: true,
                colorDark: COLORS.MAIN,
                screenWidth: window.innerWidth,
                plots: [
                    {key: "emotion", value: "EMOTIONS"},
                    {key: "feeling", value: "SENSATIONS"},
                    {key: "body", value: "BODY"},
                    {key: "reasonGroup", value: "TRIGGERS"}
                ],
                plots_ru: [
                    {key: "emotion", value: "ЭМОЦИИ"},
                    {key: "feeling", value: "ОЩУЩЕНИЯ"},
                    {key: "body", value: "ТЕЛО"},
                    {key: "reasonGroup", value: "ТРИГГЕРЫ"}
                ],
                timeTabs: [
                    {key: "TODAY", value: "TODAY"},
                    {key: "WEEK", value: "WEEK"},
                    {key: "MONTH", value: "MONTH"},
                    {key: "YEAR", value: "YEAR"}
                ],
                timeTabs_ru: [
                    {key: "TODAY", value: "СЕГОДНЯ"},
                    {key: "WEEK", value: "НЕДЕЛЯ"},
                    {key: "MONTH", value: "МЕСЯЦ"},
                    {key: "YEAR", value: "ГОД"}
                ],
                moodTabs: [
                    {key: "ALL", value: "ALL"},
                    {key: "BAD", value: "BAD"},
                    {key: "FINE", value: "FINE"},
                    {key: "GOOD", value: "GOOD"},
                ],
                moodTabs_ru: [
                    {key: "ALL", value: "ВСЕ"},
                    {key: "BAD", value: "ПЛОХО"},
                    {key: "FINE", value: "НОРМ"},
                    {key: "GOOD", value: "ХОРОШО"},
                ],
                displayBodyBart: "all",
                selectedMood: "all",
                displayTime: 1,
                selectedTimeTab: 0,
                selectedMoodTab: 0,
                userData: {},
                selectedDates: [],
                userDataSelectedByTime: {},
                moodLineData: [],
                diaryData: {},
                emotionData: {},
                emotionsMax: 1,
                reasonGroupData: {},
                reasonGroupsMax: 1,
                bodyData: {},
                bodyMax: 1,
                feelingData: {},
                feelingMax: 1,
                selectedEmotion: "",
                selectedReasonGroup: "",
                selectedBody: "",
                selectedFeeling: "",

            };
        },
        beforeCreate() {
            this.$router.replace({'query': null});
        },
        mounted() {
            this.fetchData()
            window.addEventListener('change-language', () => {
                this.language = localStorage.getItem("language");
            });
            if(!localStorage.getItem("language")){
                this.setLanguage(this.language)
            }else{
                this.language = localStorage.getItem("language")
            }
        },
        computed: {},
        created() {
            window.addEventListener("resize", this.handleResize);
        },
        watch: {},
        methods: {
            setLanguage(lng){
                localStorage.setItem("language", lng)
                window.dispatchEvent(new CustomEvent('change-language', {
                }));
            },

            fetchData() {
                this.error = this.user = null
                this.loading = true
                const fetchedId = this.$route.params.patientId
                this.userData = []
                // const telegramId = this.$route.query.id
                GraphqlService.loadUserData(fetchedId).then((data) => {
                    //console.log(data)
                    if (data.length == 0) {
                        this.userNotFound = true;
                    }
                    if (data.length != 0) {
                        this.user = data.user.user;
                        this.userData = data.user.results
                        this.language = this.user.language;
                        this.setLanguage(this.language)
                    }
                    //console.log(data)
                    // r.date, r.mood, r.moodEmotions.map(e => { return [e.bodyParts, e.emotions, e.reasonGroups]
                    this.setDatesToDisplay();
                    this.setUserDataSelectedByTime();
                    //this.setMoodData();
                    this.setMoodEmotionsAndReasonData();
                    this.loading = false;
                }, () => {
                    //console.log(error)
                    this.moodLineData = [];
                    this.userNotFound = true;
                })


            },

            setDatesToDisplay() {
                if (this.displayTime > 0) {
                    let date = new Date();
                    this.selectedDates = [];
                    this.selectedDates.push(date.toISOString().split('T')[0])
                    for (let i = 1; i < this.displayTime; i++) {
                        date.setDate(date.getDate() - 1);
                        this.selectedDates.push(date.toISOString().split('T')[0])
                    }
                }
            },

            setUserDataSelectedByTime() {
                this.userDataSelectedByTime = [];
                this.userData.forEach(record => {
                    if (record.date && this.displayTime > 0 && this.selectedDates.includes(record.date)) {
                        this.userDataSelectedByTime.push(record)
                        //console.log(JSON.parse(JSON.stringify(record)))
                    }
                })
            },

            getMoodScore(mood) {
                switch (mood.toLowerCase()) {
                    case 'terrible':
                        return 1;
                    case 'bad':
                        return 2;
                    case 'fine':
                        return 3;
                    case 'good':
                        return 4;
                    case 'great':
                        return 5;
                    case 'ужасно':
                        return 1;
                    case 'плохо':
                        return 2;
                    case 'нормально':
                        return 3;
                    case 'хорошо':
                        return 4;
                    case 'отлично':
                        return 5;
                }
            },

            setMoodData() {
                this.moodLineData = [];
                let d = this.userDataSelected.length > 0 ? this.userDataSelected : this.userDataSelectedByTime
                d.forEach(record => {
                    this.moodLineData.push(this.getMoodScore(record.mood.toLowerCase()));
                });
                if (this.moodLineData.length == 1) {
                    this.moodLineData.push(this.moodLineData[0])
                }
            },

            filterData() {
                this.userDataSelected = []
                this.userDataSelectedByTime.forEach((record) => {
                    if (this.checkMood(record.mood)) {
                        record.moodEmotions.forEach(moodEmotion => {
                            let bodyMatch = false;
                            let emotionMatch = false;
                            let reasonGroupMatch = false;
                            let feelingMatch = false;
                            if (!this.selectedEmotion) {
                                emotionMatch = true;
                            } else {
                                moodEmotion.emotions.forEach(emotion => {

                                    if (this.checkEmotion(emotion)) {
                                        emotionMatch = true;
                                    }
                                })
                            }

                            if (emotionMatch) {
                                if (!this.selectedBody) {
                                    bodyMatch = true;
                                } else {
                                    moodEmotion.bodyParts.forEach(body => {
                                        if (this.checkBody(body)) {
                                            bodyMatch = true;
                                        }
                                    })
                                }
                            }

                            if (bodyMatch) {
                                if (!this.selectedFeeling) {
                                    feelingMatch = true;
                                } else {
                                    moodEmotion.feelings.forEach(feeling => {
                                        if (this.checkFeeling(feeling)) {
                                            feelingMatch = true;
                                        }
                                    })
                                }
                            }

                            if (feelingMatch) {
                                if (!this.selectedReasonGroup) {
                                    reasonGroupMatch = true;
                                } else {
                                    moodEmotion.reasonGroups.forEach(reasonGroup => {
                                        if (!this.selectedReasonGroup || this.checkReasonGroup(reasonGroup)) {
                                            reasonGroupMatch = true;
                                        }
                                    })
                                }
                            }

                            if (bodyMatch && reasonGroupMatch && feelingMatch && emotionMatch) {
                                this.userDataSelected.push({
                                    date: record.date,
                                    mood: record.mood,
                                    moodEmotions: [moodEmotion]
                                })
                            }

                        })
                    }
                })
                localStorage.setItem("userDataSelected", JSON.stringify(this.userDataSelected));

            },

            setMoodEmotionsAndReasonData() {
                this.emotionData = {}
                this.reasonGroupData = {}
                this.bodyData = {}
                this.feelingData = {}
                this.filterData()
                this.setMoodData()
                this.userDataSelected.forEach((record) => {
                    let moodScore = this.getMoodScore(record.mood)
                    record.moodEmotions.forEach(moodEmotion => {
                        // add emotions
                        moodEmotion.emotions.forEach(emotion => {
                            emotion = emotion.toLowerCase();
                            if (!this.emotionData[emotion]) {
                                this.emotionData[emotion] = [1, moodScore];
                            } else {
                                let currentValue = this.emotionData[emotion]
                                this.emotionData[emotion] = [currentValue[0] + 1, currentValue[1] + moodScore];
                            }
                        });
                        // add reasonGroups
                        moodEmotion.reasonGroups.forEach(reasonGroup => {
                            reasonGroup = reasonGroup.toLowerCase();
                            if (!this.reasonGroupData[reasonGroup]) {
                                this.reasonGroupData[reasonGroup] = [1, moodScore];
                            } else {
                                let currentValue = this.reasonGroupData[reasonGroup]
                                this.reasonGroupData[reasonGroup] = [currentValue[0] + 1, currentValue[1] + moodScore];
                            }
                        })

                        // add body
                        moodEmotion.bodyParts.forEach(body => {
                            body = body.toLowerCase();
                            if (!this.bodyData[body]) {
                                this.bodyData[body] = [1, moodScore];
                            } else {
                                let currentValue = this.bodyData[body]
                                this.bodyData[body] = [currentValue[0] + 1, currentValue[1] + moodScore];
                            }
                        })

                        // add feeling
                        moodEmotion.feelings.forEach(feeling => {
                            feeling = feeling.toLowerCase();
                            if (!this.feelingData[feeling]) {
                                this.feelingData[feeling] = [1, moodScore];
                            } else {
                                let currentValue = this.feelingData[feeling]
                                this.feelingData[feeling] = [currentValue[0] + 1, currentValue[1] + moodScore];
                            }
                        })

                    });
                })
                this.emotionsMax = Math.max(...Object.values(this.emotionData));
                this.reasonGroupsMax = Math.max(...Object.values(this.reasonGroupData));
                this.bodyMax = Math.max(...Object.values(this.bodyData));
                this.feelingMax = Math.max(...Object.values(this.feelingData));
            },

            checkMood(mood) {
                mood = mood.toLowerCase()
                if (this.selectedMood == "all") {
                    return true;
                }
                if (this.selectedMood == "bad" && (mood == "terrible" || mood == "bad" || mood == "ужасно" || mood == "плохо")) {
                    return true;
                } else if (this.selectedMood == "good" && (mood == "good" || mood == "great" || mood == "хорошо" || mood == "отлично")) {
                    return true;
                } else if (this.selectedMood == "fine" && (mood == "fine" || mood == "нормально")) {
                    return true;
                }
                return false;
            },
            checkBody(body) {
                body = body.toLowerCase()
                if (this.selectedBody == body) {
                    return true;
                }
                return false;
            },
            checkEmotion(emotion) {
                emotion = emotion.toLowerCase()
                if (this.selectedEmotion == emotion) {
                    return true;
                }
                return false;
            },
            checkReasonGroup(reasonGroup) {
                reasonGroup = reasonGroup.toLowerCase()
                if (this.selectedReasonGroup == reasonGroup) {
                    return true;
                }
                return false;
            },
            checkFeeling(feeling) {
                feeling = feeling.toLowerCase()
                if (this.selectedFeeling == feeling) {
                    return true;
                }
                return false;
            },

            selectTimeTab(index) {
                this.selectedTimeTab = index;
                let tab = this.timeTabs[index]
                if (tab.key.toLowerCase() == "today") {
                    this.displayTime = 1
                } else if (tab.key.toLowerCase() == "week") {
                    this.displayTime = 7
                } else if (tab.key.toLowerCase() == "month") {
                    this.displayTime = 30
                } else {
                    this.displayTime = 365;
                }
                this.setDatesToDisplay();
                this.setUserDataSelectedByTime();
                this.setMoodData();
                this.setMoodEmotionsAndReasonData();
            },
            selectMoodTab(index) {
                this.selectedMoodTab = index;
                let tab = this.moodTabs[index]
                this.selectedMood = tab.key.toLowerCase();
                this.setMoodEmotionsAndReasonData();
            },

            selectEmotion(word) {
                this.selectedEmotion = word[0].toLowerCase();
                this.setMoodEmotionsAndReasonData();
            },
            deselectEmotion() {
                this.selectedEmotion = "";
                this.setMoodEmotionsAndReasonData();
            },
            selectReasonGroup(word) {
                this.selectedReasonGroup = word[0].toLowerCase();
                this.setMoodEmotionsAndReasonData();
            },
            deselectReasonGroup() {
                this.selectedReasonGroup = "";
                this.setMoodEmotionsAndReasonData();
            },
            selectFeeling(word) {
                this.selectedFeeling = word[0].toLowerCase();
                this.setMoodEmotionsAndReasonData();
            },
            deselectFeeling() {
                this.selectedFeeling = "";
                this.setMoodEmotionsAndReasonData();
            },
            selectBody(word) {
                this.selectedBody = word[0].toLowerCase();
                this.setMoodEmotionsAndReasonData();
            },
            deselectBody() {
                this.selectedBody = "";
                this.setMoodEmotionsAndReasonData();
            },

            handleResize() {
                this.screenWidth = window.innerWidth
            },
        }
    }

</script>

<style scoped>
    .normal-text {
        color: rgba(0, 0, 0, 0.54);
        font-size: 0.975rem;
        letter-spacing: 0.0892857143em;
    }

    .no-padding {
        padding: 0px !important;
        margin: 0px !important;
    }

    .with-margin {
        margin: 12px !important;
    }

    .light-background {
        background-color: white;
    }

    .dark-text {
        color: #9eb8b7;
    }

    /deep/ .v-toolbar__content {
        padding: 0px !important;
    }

    .my-overlay >>> .v-overlay__content {
        width: 100%;
        height: 100%;
    }


</style>