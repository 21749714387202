<template>
    <v-row style="margin-bottom: 5px">
        <v-col style="padding-top: 10px !important; padding-bottom: 1px !important; margin: 0px !important;">
            <v-tabs :class="[{ 'no-padding': screenWidth<960 }]"
                    fixed-tabs
                    :background-color="tabColor"
                    :slider-color="tabColor"
                    centered
                    dark
                    show-arrows
                    v-model="selectedTab"
            >
                <v-tab style="padding: 0px !important; margin: 5px"
                       v-for="(tab, index) in tabs"
                       :key="tab.key"
                       @click="selectDatesToDisplay(index)"

                >
                    {{tab.value}}
                </v-tab>
            </v-tabs>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: "FilterTabs",
        props: ['screenWidth', "tabs", "selectedTab", "tabColor"],
        methods: {
            selectDatesToDisplay(index) {
                this.$emit('clicked', index)
            },
        },

    }
</script>

<style scoped>

</style>