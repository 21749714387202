import {gql, GraphQLClient} from "graphql-request";

const queryLoadUser = gql`
  query loadUser {
    user {
        user {
            id
            language
            firstName
        }
        results {
            date
            mood
            moodEmotions {
                bodyParts
                emotions
                reasons
                feelings
                reasonGroups
                reasonDetails
            }
        }
    }
  }
`
const graphqlClient = new GraphQLClient(process.env.VUE_APP_GRAPHQL_API_URL, { headers: {} })


export class GraphqlServiceClass {

    async loadUserData() {
        const telegramAuth = localStorage.getItem("telegramAuth");

        return await graphqlClient.request(queryLoadUser, {}, {
            'Authorization': 'Telegram ' + telegramAuth
        }).then(data => {
            return data
        }, () => {
            //console.log(reason)
            return []
        });
    }
}

export const GraphqlService = new GraphqlServiceClass();
