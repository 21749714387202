<template>
    <v-container fluid style="min-height: 90vh" :class="[{ 'desktop': screenWidth>600 }]">
        <v-row class="normal-text">
            <v-col
                    cols="12"
                    :class="[{ 'no-padding': screenWidth<600 }]"
            >
                <v-row>
                    <v-tabs :class="[{ 'no-padding': screenWidth<960 }]"
                            fixed-tabs
                            :background-color="colorDark"
                            :slider-color="colorDark"
                            dark
                            style="padding: 0px !important; justify-content: flex-start; align-content: flex-start"
                    >
                        <v-tab style="padding: 0px !important; margin-left: 5px !important; justify-content: flex-start; justify-self: flex-start"
                               @click="$router.push('results')"
                        >
                            <v-icon color="white" style="padding-left: 10px; padding-right: 5px;">mdi-arrow-left
                            </v-icon>
                            <span v-if="language=='ru'">РЕЗУЛЬТАТЫ</span>
                            <span v-else>RESULTS</span>
                        </v-tab>

                    </v-tabs>
                </v-row>
                <v-row align-content="center" style="padding: 15px" class="light-background">
                    <p v-if="language=='ru'">
                        Данная страница пока доступна только на английском языке.
                    </p>
                </v-row>
                <v-row align-content="center" style="padding: 15px" class="light-background">
                    <p><strong>
                        PRIVACY POLICY
                    </strong></p>
                    <p>
                        This privacy policy governs your use of the software service moodspot (“Application”) that consists
                        of Telegram bot @moodspot_bot and web portal moodspotlab.com.
                        The Application provides for the tracking of a user’s emotion-related data and is not intended
                        as a substitute for the services of a medical professional.
                    </p>
                    <p>
                        <strong>
                            GENERAL INFORMATION
                        </strong>
                    </p>
                    <p>
                        We, PirozhochekLAB GbR, (hereinafter “Company”) collect and process your personal data related
                        to the moodspot service (hereinafter also “Application”)
                        and are the “data controller” in terms of the General Data Protection Regulation (GDPR).
                        <br>
                        <br>
                        The protection and confidentiality of your data is very important to us.
                        We therefore only process your data to the extent that:
                        <br>
                        - it is necessary to provide the services you are requesting
                        <br>
                        - you have given your consent to the processing
                        <br>
                        - we are otherwise authorized to do so under the data protection laws
                        <br>
                        <br>
                        We always separately obtain consent from you for the processing of your personal data revealing
                         racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership,
                        genetic data, biometric data, data concerning health, data concerning sex life or sexual orientation
                        according to Art. 9 Abs. 2 lit. a of the General Data Protection Regulation (GDPR).
                        <br>
                        <br>
                        You can give your consent to the processing of this data, by selecting "Consent" option in
                        response to the respective moodspot_bot message.
                        Your consent will be logged by us.
                    </p>
                    <p>
                        <strong>
                            WHAT DATA IS COLLECTED
                        </strong>
                    </p>
                    <p>
                        <strong>
                            User Provided Information:
                        </strong>
                        when you use the Application, you generally provide
                        (a) transaction-related information,
                        (b) information you provide us if you contact us for help,
                        (c) information you enter into our system when using the Application, such as selected menu
                        options, mood, emotions, body sensations and emotion cause data.
                    </p>
                    <p>
                        <strong>
                            Automatically Collected Information:
                        </strong>
                        the Application may collect certain information automatically, including, Telegram ID, Telegram
                        user name, and information about the way you use the Application.
                    </p>
                    <p>
                        <strong>HOW IS DATA PROCESSED</strong>
                    </p>
                    <p>
                        We collect and store your personal, extended personal, health and Application usage data while
                        you use Application.
                        <br>
                        Only aggregated, anonymized data may be periodically transmitted to external services and
                        parties.
                        We will share your information with third parties only in the ways that are described in this
                        privacy statement.
                        <br>
                        <br>
                        <strong>We may disclose User Provided and Automatically Collected Information:</strong>
                        <br>
                        (a) as required by law, such as to comply with a subpoena, or similar legal process;
                        <br>
                        (b) when we believe in good faith that disclosure is necessary to protect our rights, protect
                        your safety or the safety of others, investigate fraud, or respond to a government request;
                        <br>
                        (c) with our trusted services providers who work on our behalf, do not have an independent use
                        of the information we disclose to them, and have agreed to adhere to the rules set forth in this
                        privacy statement.
                        <br>
                        (d) if the Company is involved in a merger, acquisition, or sale of all or a portion of its
                        assets, you will be notified via Telegram message and/or a prominent notice on our Web site of
                        any change in ownership or uses of this information, as well as any choices you may have
                        regarding this information.
                    </p>
                    <p>
                        <strong>WHAT IS THE DATA PROCESSING PURPOSE</strong>
                    </p>
                    <p>
                        We collect, processes, and use your data to provide the services mentioned our Terms of Service.
                        By providing us with your information, we can provide our services.
                        <br>
                        <br>
                        We may also use the information you provided us to contact you from time to time to provide you
                        with important information, required notices and marketing promotions.
                        <br>
                        <br>
                        You are not obliged to provide your personal data (Art. 13 GDPR). The use of our Application and
                        related services is voluntary.
                    </p>
                    <p>
                        <strong>DATA STORAGE AND SECURITY</strong>
                    </p>
                    <p>
                        We are concerned about safeguarding the confidentiality of your information.
                        <br>
                        <br>
                        Communications between moodspot_bot, moodspotlab.com and our servers takes place over secure HTTPS
                        connections and your data is encrypted when it is stored on AWS servers located in EU.
                        <br>
                        <br>
                        Your data will be stored by us as long as this is necessary for the use of our Application and
                        the services associated with it.
                        The anonymized and transactional data that does not contain your personal information can also be stored indefinitely for research and software monitoring purposes.
                        <br>
                        <br>
                        Please be aware, that since we use Telegram messenger as the data input tool your data might be
                        stored and processed by Telegram FZ LLC and Telegram Messenger Inc. with respect to their
                        Privacy policy.
                        <br>
                        <br>
                        Please be aware that, although we endeavor to provide reasonable security for information we
                        process and maintain, no security system can prevent all potential security breaches.
                    </p>
                    <p>
                        <strong>YOUR RIGHTS</strong>
                    </p>
                    <p>
                        You can stop collection of information by the Application easily by stopping the use of the
                        Application. You may also choose to delete your account together with all stored information at any time
                        through corresponding moodspot_bot functionality.
                        <br>
                        <br>
                        - If you’d like us to provide you with all the User Provided Data that you have provided via the
                        Application, please contact us at pirozhochek.lab@gmail.com.
                        <br>
                        <br>
                        - If you’d like us to change data that you provided via the
                        Application, please contact us at pirozhochek.lab@gmail.com.
                        <br>
                        <br>
                        - If you’d like us delete all User Provided Data that you have provided via the Application,
                        please contact us at pirozhochek.lab@gmail.com or use corresponding moodspot_bot functionality
                        (delete account).
                    </p>
                    <p>
                        <strong>CHILDREN</strong>
                    </p>
                    <p>
                        We do not use the Application to knowingly solicit data from or market to children under the age
                        of 13.
                        If a parent or guardian becomes aware that his or her child has provided us with information
                        without their consent, he or she should contact us at pirozhochek.lab@gmail.com.
                        We will delete such information from our files within a reasonable time.
                    </p>
                    <p>
                        <strong>CHANGES</strong>
                    </p>
                    <p>
                        This Privacy Policy may be updated from time to time for any reason. We will notify you of any
                        changes to our Privacy Policy by posting the new Privacy Policy on our website and informing you
                        via email or the Application itself. You are advised to consult this Privacy Policy regularly
                        for any changes,
                        as continued use is deemed approval of all changes.
                    </p>
                    <p>
                        <strong>CONTACT</strong>
                    </p>
                    If you have any questions regarding privacy while using the Application, or have questions about our
                    practices,
                    please contact us via email at pirozhochek.lab@gmail.com.
                    <p>
                        <br>
                        <strong>Last updated: </strong>December 8th, 2021
                    </p>
                </v-row>
            </v-col>
        </v-row>
    </v-container>


</template>


<script>
    import {COLORS} from "../main";

    export default {
        name: "Privacy",
        components: {},
        provide: {},
        data() {
            return {
                language:"en",
                screenWidth: window.innerWidth,
                colorDark: COLORS.MAIN,
            };
        },
        beforeCreate() {
        },
        mounted() {
            window.addEventListener('change-language', () => {
                this.language = localStorage.getItem("language");
            });
            if(!localStorage.getItem("language")){
                this.setLanguage(this.language)
            }else{
                this.language = localStorage.getItem("language")
            }
        },
        computed: {},
        created() {
            window.addEventListener("resize", this.handleResize);
        },
        watch: {},
        methods: {
            handleResize() {
                this.screenWidth = window.innerWidth
            },
        },
    }
</script>

<style scoped>
    .normal-text {
        color: rgba(0, 0, 0, 0.54);
        font-size: 0.975rem;
        letter-spacing: 0.0892857143em;
    }

    .no-padding {
        padding: 0px !important;
        margin: 0px !important;
    }

    .with-margin {
        margin: 12px !important;
    }

    .dark-background {
        background-color: #89b0ae;
    }

    .light-background {
        background-color: white;
    }

    .colored-text {
        color: #89b0ae;
    }


</style>