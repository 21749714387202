<template>
    <v-sparkline
            :value="value"
            :gradient="gradient"
            :smooth="radius || false"
            :padding="padding"
            :line-width="width"
            :stroke-linecap="lineCap"
            :gradient-direction="gradientDirection"
            :fill="fill"
            :type="type"
            :auto-line-width="autoLineWidth"
            auto-draw
            style="padding: 0px; margin: 0px"
    ></v-sparkline>
</template>

<script>
    export default {
        name: "MoodLine",
        data: () => ({
            width: 2,
            radius: 10,
            padding: 8,
            lineCap: 'round',
            gradientDirection: 'top',
            fill: false,
            type: 'trend',
            autoLineWidth: false,
        }),
        props: ['value', "gradient"],
    }
</script>

<style scoped>

</style>