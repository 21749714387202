import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Impressum from "../views/Impressum";
import About from "../views/About";
import Dairy from "../views/Dairy";
import Privacy from "../views/Privacy";
import Terms from "../views/Terms";

Vue.use(VueRouter)

const routes = [
    {
        path: '/results',
        name: 'Results',
        props: (route) => {
            if (route.query.id && route.query.hash) {
                localStorage.setItem("telegramAuth", new URLSearchParams(route.query).toString())
            }
            return {
                id: route.query.id,
                username: route.query.username,
                first_name: route.query.first_name,
                last_name: route.query.last_name,
                photo_url: route.query.photo_url,
                auth_date: route.query.auth_date,
                hash: route.query.hash,
            };
        },
        component: Home
    },
    {
        path: '/impressum',
        name: 'Impressum',
        component: Impressum
    },
    {
        path: '/about',
        name: 'About',
        component: About
    },
    {
        path: '/terms',
        name: 'Terms',
        component: Terms
    },
    {
        path: '/privacy',
        name: 'Privacy',
        component: Privacy
    },
    {
        path: '/dairy',
        name: 'Dairy',
        component: Dairy
    },
    {path: "*", redirect: '/about', component: About}
]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior() {
        //console.log(to + from + savedPosition)
        return {x: 0, y: 0}
    }
})

export default router
