import { render, staticRenderFns } from "./App.vue?vue&type=template&id=01a46f4d&"
import script from "./App.vue?vue&type=script&lang=js&"
export * from "./App.vue?vue&type=script&lang=js&"
import style0 from "./App.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VMain } from 'vuetify/lib/components/VMain';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VApp,VBtn,VCol,VContainer,VFooter,VMain,VRow,VSnackbar})
