<template>
    <v-row v-if="language=='ru'">
        <v-col >
            <p style="padding: 15px"><strong>
                Данные не найдены
            </strong></p>
            <p  v-if="userNotFound" style="padding: 15px">
                Чтобы посмотреть результаты, ты должен зайти через Телеграм.
                <br>
                <br>
                Для этого в главном меню <a href="https://t.me/moodspot_bot">@moodspot_bot</a> выбери <strong>результаты</strong> и перейди по ссылке.
            </p>
            <p  v-else style="padding: 15px">
                Сделай хотя бы одну запись, чтобы посмотреть результаты
            </p>
        </v-col>
    </v-row>
    <v-row v-else>
        <v-col>
            <p style="padding: 15px"><strong>
                No data found
            </strong></p>
            <p  v-if="userNotFound" style="padding: 15px">
                To see results you must authenticate via Telegram.
                <br>
                <br>
                Please select <strong>results</strong> in main menu of <a href="https://t.me/moodspot_bot">@moodspot_bot</a> and follow the link.
            </p>
            <p v-else style="padding: 15px">
                Please enter at least one emotion to see results
            </p>
        </v-col>
    </v-row>

</template>

<script>
    export default {
        name: "NoDataInfo",
        props:["language", 'userNotFound'],

    }
</script>

<style scoped>

</style>