<template>
    <v-container fluid style="min-height: 90vh" :class="[{ 'desktop': screenWidth>600 }]">
        <v-row class="normal-text" >
            <v-col
                    cols="12"
                    :class="[{ 'no-padding': screenWidth<600 }]"
            >
                <v-row>
                    <v-tabs :class="[{ 'no-padding': screenWidth<960 }]"
                            fixed-tabs
                            :background-color="colorDark"
                            :slider-color="colorDark"
                            dark
                            style="padding: 0px !important; justify-content: flex-start; align-content: flex-start"
                    >
                        <v-tab style="padding: 0px !important; margin-left: 5px !important; justify-content: flex-start; justify-self: flex-start"
                               @click="$router.push('results')"
                        >
                            <v-icon color="white" style="padding-left: 10px; padding-right: 5px;">mdi-arrow-left
                            </v-icon>
                            <span v-if="language=='ru'">РЕЗУЛЬТАТЫ</span>
                            <span v-else>RESULTS</span>
                        </v-tab>

                    </v-tabs>
                </v-row>
                <v-row align-content="center" style="padding: 15px" class="light-background">
                    <p v-if="language=='ru'">
                        Данная страница пока доступна только на английском языке.
                    </p>
                </v-row>
                <v-row  align-content="center" class="light-background" style="padding: 15px !important;">
                    <p><strong>
                        TERMS OF SERVICE
                    </strong></p>
                    <br/>
                    <p>
                        Welcome to moodspot!
                        These terms of service ("Terms") apply to your access and use of moodspot (the "Service") provided by PirozhochekLAB GbR (the "Company").
                        Please read them carefully.
                    </p>
                    <p>
                        Please note that our service relies on Telegram Messenger as a data collection tool.
                        In order to use the Service you must create a Telegram account and agree to Telegram Terms of Service https://telegram.org/tos.
                    </p>
                    <p>
                        <strong>FUNCTIONALITY</strong>
                    </p>
                    <p>
                        By accepting these terms of service you get access to following functionality:
                        <br>
                        <br>
                        <strong>moodspot_bot</strong>
                        <br>
                        - enter information regarding your current mood
                        <br>
                        - enter information regarding emotions you currently experience
                        <br>
                        - enter information about body sensations that are associated with emotions you currently experience
                        <br>
                        - enter information about external and internal events that might be the cause of the emotions you currently experience
                        <br>
                        - delete your account and all information linked to it
                        <br>
                        <br>
                        <strong>moodspotlab.com</strong>
                        <br>
                        - view aggregated and color-coded information you entered via moodspot_bot (mood, emotions, body sensations, triggers)
                        <br>
                        - view filtered and ordered information you entered
                        <br>
                        - view detailed information that you entered about internal and external emotion triggers
                        <br>
                        - identify links between information pieces that you entered
                    </p>
                    <p>
                        <strong>ACCEPTING THESE TERMS</strong>
                    </p>
                    <p>
                        If you access or use the Service, it means you agree to be bound by all of the terms below.
                        So, before you use the Service, please read all of the terms.
                        If you don't agree to all of the terms below, please do not use the Service.
                        Also, if a term does not make sense to you, please let us know by emailing pirozhochek.lab@gmail.com.
                    </p>

                    <p>
                        <strong>CHANGES TO THESE TERMS</strong>
                    </p>
                    <p>
                        We reserve the right to modify these Terms at any time. For instance, we may need to change these Terms if we come out with a new feature or for some other reason.
                        Whenever we make changes to these Terms, the changes are effective at the time we post such revised Terms
                        (indicated by revising the date at the top of these Terms) or upon your acceptance if we provide a mechanism for your immediate acceptance of the revised Terms (such as a click-through confirmation or acceptance button). It is your responsibility to check the Service for changes to these Terms.
                        If you continue to use the Service after the revised Terms go into effect, then you have accepted the changes to these Terms.

                    </p>
                    <p>
                        <strong>NOT MEDICAL ADVICE</strong>
                    </p>
                    <p>
                        You agree that the Service constitutes, in part, a self-help system designed to help you record your own mood and reflect on your emotions,
                        and that if you choose to utilize the Service you are solely responsible for deciding which of the
                        suggested techniques you put into practice and how you apply those techniques.
                        As such, you agree that the Company is not a healthcare provider, and does not provide you with any actual medical advice.
                        <br>You also agree, that the Service is not a medical device and that it shall not be used as a diagnostic tool for any medical condition.
                    </p>
                    <p>
                        <strong>FEES</strong>
                        <br>
                        <br>
                    The Service provides it's basic functionality free of charge

                    </p>
                    <p>The Company plans to offers certain enhanced features of the Service which can later be purchases as a subscription (“Subscription”)</p>
                    <p><strong>Changes to Price Terms: </strong>
                        The Company reserves the right to change its pricing terms at any time and the Company will notify you in advance of such changes becoming effective.
                        Changes to the pricing terms will not apply retroactively.
                        If you do not agree with the changes to the Service's pricing terms then you may choose to either stop using the Service or delete your account completely.
                    <p>
                        <strong>PRIVACY POLICY</strong>
                    </p>
                    <p>
                        For information about how we collect and use information about users of the Service,
                        please check out our privacy policy available at <router-link to="privacy">Privacy</router-link> page.
                    </p>
                    <p>
                        <strong>THIRD-PARTY SERVICES</strong>
                    </p>
                    <p>
                        We may provide you with links to third party websites or services that we do not own or control. Your use of the Service may also include the use of applications that are developed or owned by a third party.
                        Your use of such third party applications, websites, and services is governed by that party's own terms of service or privacy policies. We encourage you to read the terms and conditions and privacy policy of any third party application, website or service that you visit or use.
                    </p>
                    <p>
                        <strong>NO LIABILITY</strong>
                    </p>
                     <p>
                         To the fullest extent permitted by applicable law, in no event will company, its affiliates,
                         or any of its or their respective licensors or service providers, employees, agents,
                         officers and/or directors, be liable for (i) any lost profits, loss of use, cost of cure,
                         diminution of value, loss of data, or for any direct, incidental, indirect, consequential, special,
                         exemplary, or punitive damages of any kind, however caused, arising out of or related to your use
                         or misuse of or inability to use the application, whether in contract,
                         tort (including negligence), strict liability, or any other legal theory, even if those damages
                         were foreseeable and even if extent was advised of the possibility of those damages; or (ii)
                         money damages, however caused, arising out of or related to this agreement or to your use or
                         misuse of or inability to use the application.
                     </p>
                    <p>
                        <strong>FEEDBACK</strong>
                    </p>
                    <p>
                        Please let us know what you think of the Service, these Terms and, in general,
                        the Service by sending email to pirozhochek.lab@gmail.com.
                        When you provide us with any feedback, comments or suggestions about the Service,
                        these Terms and, in general, the Service, you irrevocably assign to us all of your right, title and interest in and to your feedback, comments and suggestions.

                    </p>
                    <p>
                        <strong>Last updated:</strong> December 8th, 2021
                    </p>
                </v-row>
            </v-col>
        </v-row>
    </v-container>


</template>


<script>
    import {COLORS} from "../main";

    export default {
        name: "Terms",
        components: {
        },
        provide: {},
        data() {
            return {
                language:"en",
                screenWidth: window.innerWidth,
                colorDark: COLORS.MAIN,
            };
        },
        beforeCreate() {
        },
        mounted() {
            window.addEventListener('change-language', () => {
                this.language = localStorage.getItem("language");
            });
            if(!localStorage.getItem("language")){
                this.setLanguage(this.language)
            }else{
                this.language = localStorage.getItem("language")
            }
        },
        computed: {},
        created() {
            window.addEventListener("resize", this.handleResize);
        },
        watch: {},
        methods: {
            handleResize() {
                this.screenWidth = window.innerWidth
            },
        },
    }
</script>

<style scoped>
    .normal-text {
        color: rgba(0, 0, 0, 0.54);
        font-size: 0.975rem;
        letter-spacing: 0.0892857143em;
    }

    .no-padding {
        padding: 0px !important;
        margin: 0px !important;
    }

    .with-margin {
        margin: 12px !important;
    }

    .dark-background {
        background-color: #89b0ae;
    }

    .light-background {
        background-color: white;
    }

    .colored-text {
        color: #89b0ae;
    }


</style>