import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import FilterTabs from "./components/FilterTabs";
import Meta from "vue-meta"

export const COLORS = {
  "BACKGROUND": "white",
  "MAIN": "#89b0ae",
  "GREAT": "#06806b",
  "GOOD": "#69b58a",
  "FINE": "#bdb348",
  "BAD": "#e38a66",
  "TERRIBLE": "#af1d3c"
};

Vue.config.productionTip = false
Vue.component('filter-tabs', FilterTabs);
Vue.use(Meta)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
