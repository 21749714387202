<template>
    <vue-word-cloud
            style="'background-color:white; height: 23vh; width: 100%; align-self: center; margin: 0px; align-content: center'"
            :words="wordMap"
            :spacing=1
            :color="colorFunction"
            :font-size-ratio="3"
            font-family="Comfortaa"

    >
        <template slot-scope="{text, weight, word}">
            <div
                    :title="weight"
                    style="cursor: pointer; z-index: -1;"
                    @click="onWordClick(word)"
            >
                {{ text }}
            </div>
        </template>
    </vue-word-cloud>
</template>

<script>
    import VueWordCloud from 'vuewordcloud';

    export default {
        name: "WordCloudConcepts",
        components: {
            [VueWordCloud.name]: VueWordCloud,
        },
        props: ['concepts', "colorFunction"],
        data: () => ({
            height: 25,
            hoverWord: null
        }),
        mounted() {
        },
        computed: {
            wordMap() {
                if (!this.concepts) {
                    return []
                }
                let result = [];
                for (let k of Object.keys(this.concepts)) {
                    result.push([k.toUpperCase(), this.concepts[k][0], this.concepts[k][1]])
                }
                result.sort(function(a,b) {
                    return b[1]-a[1]
                });
                return result.slice(0, 25);
            },
        },
        methods: {
            onWordOver(word) {
                this.hoverWord = word
            },
            onWordClick(word) {
                this.$emit('clicked', word)
            },
        }
    }
</script>

<style scoped>

</style>
