<template>
    <v-row
            style="padding-bottom: 0; padding-left:10px; padding-right:10px">
        <v-col cols="12" >
            <v-row
                    class="light-background"
                    style="padding-top: 10px; padding-left:10px; padding-right:10px;">
                <v-col cols="12" v-if="!selectedConcept">
                    <word-cloud-concepts
                            @clicked="selectConcept"
                            :concepts="conceptData"
                            :color-function="([word, weight, mood]) => getWordColor(weight, mood)"
                    >
                    </word-cloud-concepts>
                </v-col>
                <v-col style="padding: 0px">
                    <v-row style="padding: 5px">
                        <v-col style="padding: 0px">
                                <span class="light-background colored-text"
                                      style="text-align: right !important; alignment: right; font-size: x-small; display: inline-block; margin: 7px !important;"
                                >
                                    {{conceptTitle}}
                                </span>
                        </v-col>
                        <v-col align="right" style="padding: 0px; padding-right: 7px !important;">
                            <v-chip
                                    v-if="selectedConcept"
                                    class="ma-2"
                                    close
                                    :color="colorDark"
                                    text-color="white"
                                    @click="closeSelectedConcept()"
                                    @click:close="closeSelectedConcept()"
                                    style="margin: 0px !important; align-self: flex-end"
                            >
                                {{selectedConcept.toUpperCase()}}
                            </v-chip>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>

        <v-col cols="12">
            <v-row v-if="divider"
                   class="light-background"
            >
                <v-col cols="12">
                    <v-divider></v-divider>
                </v-col>
            </v-row>

        </v-col>

    </v-row>

</template>

<script>
    import WordCloudConcepts from "./WordCloudConcepts";
    import {COLORS} from "../main";

    export default {
        name: "WordCloudBlock",
        components: {
            [WordCloudConcepts.name]: WordCloudConcepts,
        },
        props: ['selectedConcept', "conceptData", "cMax", "conceptTitle", "colorDark", "divider", "colorWords"],
        data: () => ({}),
        mounted() {
        },
        computed: {},
        methods: {
            closeSelectedConcept() {
                this.$emit('de-select-concept')
            },
            selectConcept(word) {
                this.$emit('select-concept', word)
            },
            getWordColor(weight, mood) {
                if (!this.colorWords) {
                    return "#616161"
                }
                if (mood / weight > 4.5) {
                    return COLORS.GREAT
                } else if (mood / weight > 3.5) {
                    return COLORS.GOOD
                } else if (mood / weight > 2.5) {
                    return COLORS.FINE
                } else if (mood / weight > 1.5) {
                    return COLORS.BAD
                }
                return COLORS.TERRIBLE
            },
        }
    }
</script>

<style scoped>

</style>