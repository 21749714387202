<template>
    <v-container fluid style="min-height: 90vh" :class="[{ 'desktop': screenWidth>600 }]">
        <v-row class="normal-text">
            <v-col
                    cols="12"
                    :class="[{ 'no-padding': screenWidth<600 }]"
            >
                <v-row>
                    <v-tabs :class="[{ 'no-padding': screenWidth<960 }]"
                            fixed-tabs
                            :background-color="colorDark"
                            :slider-color="colorDark"
                            dark
                            style="padding: 0px !important; justify-content: flex-start; align-content: flex-start"
                    >
                        <v-tab style="padding: 0px !important; margin-left: 5px !important; justify-content: flex-start; justify-self: flex-start"
                               @click="$router.push('results')"
                        >
                            <v-icon color="white" style="padding-left: 10px; padding-right: 5px;">mdi-arrow-left
                            </v-icon>
                            <span v-if="language=='ru'">РЕЗУЛЬТАТЫ</span>
                            <span v-else>RESULTS</span>
                        </v-tab>

                    </v-tabs>
                </v-row>
                <v-row  align-content="center" class="light-background" style="padding: 15px !important;">
                    <div>
                        <p>
                           <strong>Impressum</strong>
                        </p>
                        <p>
                            www.moodspotlab.com ist ein Angebot der
                            PirozhochekLAB GbR
                        </p>
                        <br>
                        <p><strong>EMAIL:</strong> pirozhochek.lab@gmail.com</p>
                        <br>
                        <p>
                            Geschaftsfuhrers: Anna Berezhkova, Alexander Kalach
                        </p>
                    </div>

                    </v-row>
            </v-col>
        </v-row>
    </v-container>


</template>


<script>
    import {COLORS} from "../main";

    export default {
        components: {
        },
        provide: {},
        data() {
            return {
                language:"en",
                screenWidth: window.innerWidth,
                colorDark: COLORS.MAIN,
            };
        },
        beforeCreate() {
        },
        mounted() {
            window.addEventListener('change-language', () => {
                this.language = localStorage.getItem("language");
            });
            if(!localStorage.getItem("language")){
                this.setLanguage(this.language)
            }else{
                this.language = localStorage.getItem("language")
            }
        },
        computed: {},
        created() {
            window.addEventListener("resize", this.handleResize);
        },
        watch: {},
        methods: {
            handleResize() {
                this.screenWidth = window.innerWidth
            },
        },
    }
</script>

<style scoped>
    .normal-text {
        color: rgba(0, 0, 0, 0.54);
        font-size: 0.975rem;
        letter-spacing: 0.0892857143em;
    }

    .no-padding {
        padding: 0px !important;
        margin: 0px !important;
    }

    .with-margin {
        margin: 12px !important;
    }

    .dark-background {
        background-color: #89b0ae;
    }

    .light-background {
        background-color: white;
    }

    .colored-text {
        color: #89b0ae;
    }


</style>