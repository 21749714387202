<template>
    <v-container fluid style="min-height: 90vh" :class="[{ 'desktop': screenWidth>600 }]">
        <v-row class="normal-text" align-content="left">
            <v-col
                    cols="12"
                    :class="[{ 'no-padding': screenWidth<600 }]"
            >
                <v-row>
                    <v-tabs :class="[{ 'no-padding': screenWidth<960 }]"
                            fixed-tabs
                            :background-color="colorDark"
                            :slider-color="colorDark"
                            dark
                            style="padding: 0px !important; justify-content: flex-start; align-content: flex-start"
                    >
                        <v-tab style="padding: 0px !important; margin-left: 5px !important; justify-content: flex-start; justify-self: flex-start"
                               @click="$router.push('results')"
                        >
                            <v-icon color="white" style="padding-left: 10px; padding-right: 5px;">mdi-arrow-left
                            </v-icon>
                            <span v-if="language=='ru'">РЕЗУЛЬТАТЫ</span>
                            <span v-else>RESULTS</span>
                        </v-tab>

                    </v-tabs>
                </v-row>
                <v-row v-if="language=='ru'">
                    <v-col>
                        <v-row style="padding: 15px; padding-bottom: 0px !important;">
                            <v-col cols="5" style="padding-left: 0px !important; padding-bottom: 0px !important;">
                                <p><strong>
                                    MOODSPOT
                                </strong></p>
                            </v-col>
                            <v-col class="text-right">
                                <v-btn small rounded depressed @click="setLanguage('en')">
                                    English
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row style="padding: 0px; padding-left: 15px !important;">
                            <v-col style="padding: 0px !important; padding-right: 5px !important;">
                                <p>Привет, это Moodspot. Я помогу тебе распознать твои эмоции, понять где и как они отзываются в теле и определить какие ситуации их вызывают.</p>
                                <p>Мы будем общаться в <a href="https://telegram.org/">Telegram</a>. Найди <a href="https://t.me/moodspot_bot">@moodspot_bot</a> и нажми /start.</p>
                                <p>Твой профиль будет также создан на сайте moodspotlab.com. Тут ты сможешь наглядно увидеть связь твоего эмоционального и физического состояния с жизненными ситуациями.</p>
                            </v-col>
                        </v-row>
                        <v-row style="background-color: #89b0ae; padding: 10px" align="center" justify="center">
                            <img src="../assets/1_overview_ru.png" style="width:90%;height:auto; align-self: center"/>
                        </v-row>
                        <v-row style="padding: 15px; padding-bottom: 0px !important;">
                            <p><strong>
                                С чего начать
                            </strong></p>
                        </v-row>
                        <v-row style="padding: 15px; padding-top: 5px !important; padding-bottom: 0px !important;">
                            <p>
                            <ul>
                                <li>Выбери <strong>Записать эмоцию</strong> и ответь на вопросы.</li>
                                <li>Посмотри на графики своих эмоций нажав на <strong>Результаты</strong>.</li>
                                <li>Нажми <strong>Настройки</strong>, чтобы подстроить бота под себя.</li>
                            </ul>
                            </p>

                        </v-row>

                        <v-row style="background-color: #89b0ae; padding: 10px" align="center" justify="center">
                            <img src="../assets/0_menu_ru.png" style="width:90%;height:auto; align-self: center"/>
                        </v-row>

                        <v-row style="padding: 15px; padding-bottom: 0px !important;">
                            <p><strong>
                                Как записать эмоцию?
                            </strong></p>
                        </v-row>
                        <v-row style="padding: 15px; padding-top: 5px !important;">
                            <p>
                            <ul>
                                <li>Подумай как ты себя чувствуешь и выбери один из предложенных вариантов.</li>
                                <li>Теперь мы попробуем определить конкретную эмоцию, которую ты сейчас испытываешь.<i>Выбери один из вариантов или запиши свой</i>
                                </li>
                                <li>Почувствуй где и как эта эмоция отзывается в теле.</li>
                                <li>Подумай и запиши, что стало ее причиной.</li>
                            </ul>
                            </p>
                        </v-row>
                        <v-row style="padding: 15px; padding-top: 5px !important;">
                            <p>Ты можешь записать только одну эмоцию за один раз, чтобы лучше ее проанализировать. Если ты испытываешь больше одной эмоции, повтори эти шаги для каждой из них.</p>
                        </v-row>
                        <v-row style="background-color: #89b0ae; padding: 10px" align="center" justify="center">
                            <img src="../assets/6_dialog_all_ru.png" style="width:90%;height:auto; align-self: center"/>
                        </v-row>
                        <v-row style="padding: 15px; padding-bottom: 0px !important;">
                            <p><strong>
                                Результаты
                            </strong></p>
                        </v-row>
                        <v-row style="padding: 15px; padding-top: 5px !important;">
                            <p>На сайте moodspotlab.com ты можешь посмотреть тренды своего настроения и увидеть связь между твоим состоянием и жизненными ситуациями.</p>
                        </v-row>
                        <v-row style="background-color: #89b0ae; padding: 10px" align="center" justify="center">
                            <img src="../assets/7_mood_ru.png" style="width:90%;height:auto; align-self: center"/>
                        </v-row>
                        <v-row style="padding: 15px; padding-top: 15px !important;">
                            <p>Ты можешь отфильтровать результаты по настроению или по времени. Нажми на любое слово на графике и посмотри, что с ним связано.</p>
                        </v-row>
                        <v-row style="background-color: #89b0ae; padding: 10px" align="center" justify="center">
                            <img src="../assets/8_filter_ru.png" style="width:90%;height:auto; align-self: center"/>
                        </v-row>
                        <v-row style="padding: 15px; padding-top: 15px !important;">
                            <p>
                                Детали каждой записи будут видны в разделе <strong>Дневник</strong>. Все фильтры с главой страницы применяются и в дневнике.
                            </p>
                        </v-row>
                        <v-row style="background-color: #89b0ae; padding: 10px" align="center" justify="center">
                            <img src="../assets/10_diary_ru.png" style="width:90%;height:auto; align-self: center"/>
                        </v-row>
                        <v-row style="padding: 15px; padding-bottom: 0px !important;">
                        </v-row>
                        <v-row style="padding: 15px; padding-bottom: 0px !important;">
                            <p><strong>
                                Настройки
                            </strong></p>
                        </v-row>
                        <v-row style="padding: 15px; padding-top: 5px !important;">
                            <ul>
                                <li>Ты можешь выбрать <strong>язык</strong>, на котором тебе комфортнее переписываться с ботом. <i>Пока что бот умеет общаться на русском и на английском</i></li>
                                <li>Ты можешь установить <strong>напоминание</strong>, чтобы не забыть ответить на вопросы. <i>Чем чаще ты рассказываешь боту о своем настроении, тем лучше у нас получится проследить динамику в твоем настроении и состоянии.</i></li>
                                <li>Ты можешь удалить все свои записи из дневника, нажав на <strong>удалить профиль</strong>. </li>
                            </ul>
                        </v-row>
                        <v-row style="padding: 15px; padding-bottom: 0px !important;">
                            <p><strong>
                                Напиши нам
                            </strong></p>
                        </v-row>
                        <v-row style="padding: 15px; padding-top: 5px !important;">
                            <p>С нами можно связаться по почте: pirozhochek.lab@gmail.com</p>
                        </v-row>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col>
                        <v-row style="padding: 15px; padding-bottom: 0px !important;">
                            <v-col cols="5" style="padding-left: 0px !important; padding-bottom: 0px !important;">
                                <p><strong>
                                    MOODSPOT
                                </strong></p>
                            </v-col>
                            <v-col class="text-right">
                                <v-btn small rounded depressed @click="setLanguage('ru')" >
                                    Русский
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row style="padding: 0px; padding-left: 15px !important;">
                            <v-col style="padding: 0px !important; padding-right: 5px !important;">
                                <p>Connect mood, emotions, body sensations and life events.</p>
                            </v-col>
                        </v-row>
                        <v-row style="padding: 0px; padding-left: 15px !important;">
                            <ul>
                                <li>Understand your <strong>mood</strong> and <strong>emotions</strong></li>
                                <li>Locate emotions in your <strong>body</strong></li>
                                <li>Discover what <strong>triggers</strong> you</li>
                            </ul>
                        </v-row>
                        <v-row style="padding: 0px; padding-left: 15px !important;">
                            <p>
                                <br>
                                <strong>with moodspot</strong>
                                <br>
                            </p>
                        </v-row>
                        <v-row style="padding: 15px; padding-top: 0px !important;">
                            <ul>
                                <li>Telegram bot <strong>@moodspot_bot</strong></li>
                                <li>Web app <strong>moodspotlab.com</strong></li>
                            </ul>
                        </v-row>
                        <v-row style="background-color: #89b0ae; padding: 10px" align="center" justify="center">
                            <img src="../assets/1_overview.png" style="width:90%;height:auto; align-self: center"/>
                        </v-row>
                        <v-row style="padding: 15px; padding-bottom: 0px !important;">
                            <p><strong>
                                HOW TO START
                            </strong></p>
                        </v-row>
                        <v-row style="padding: 15px; padding-top: 5px !important; padding-bottom: 0px !important;">
                            <ul>
                                <li>Install <a href="https://telegram.org/">Telegram</a> messenger. <br> <i>We use it as
                                    the data input tool.</i></li>
                                <li>Find <a href="https://t.me/moodspot_bot">@moodspot_bot</a></li>
                                <li>Click <strong>/start</strong> 😉</li>
                            </ul>
                            <p>
                                <br>
                                <i>Your moodspot account will be created automatically and linked to your Telegram
                                    ID.</i>
                                <br>
                            </p>
                        </v-row>

                        <v-row style="padding: 15px; padding-bottom: 0px !important;">
                            <p><strong>
                                WHAT TO DO
                            </strong></p>
                        </v-row>
                        <v-row style="padding: 15px; padding-top: 5px !important;">
                            <ul>
                                <li>Select <strong>check-in</strong> to answer question</li>
                                <li>See <strong>results</strong> to view your progress</li>
                                <li>Check <strong>settings</strong> to customize your experience</li>
                            </ul>
                        </v-row>
                        <v-row style="background-color: #89b0ae; padding: 10px" align="center" justify="center">
                            <img src="../assets/0_menu.png" style="width:90%;height:auto; align-self: center"/>
                        </v-row>
                        <v-row style="padding: 15px; padding-bottom: 0px !important;">
                            <p><strong>
                                CHECK-IN
                            </strong></p>
                        </v-row>
                        <v-row style="padding: 15px; padding-top: 5px !important;">
                            <ul>
                                <li>Enter your <strong>mood</strong></li>
                                <li>Define exact <strong>emotion</strong> <br> <i>Focus on one emotion at a time</i>
                                </li>
                                <li>Locate and sense emotion in your <strong>body</strong></li>
                                <li>Identify its' <strong>trigger</strong></li>
                                <li>Repeat <i>in case of mixed emotions</i></li>
                            </ul>
                        </v-row>
                        <v-row style="background-color: #89b0ae; padding: 10px" align="center" justify="center">
                            <img src="../assets/6_dialog_all.png" style="width:90%;height:auto; align-self: center"/>
                        </v-row>
                        <v-row style="padding: 15px; padding-bottom: 0px !important;">
                            <p><strong>
                                RESULTS
                            </strong></p>
                        </v-row>
                        <v-row style="padding: 15px; padding-top: 5px !important;">
                            <ul>
                                <li>Check out your <strong>mood trend</strong></li>
                            </ul>
                        </v-row>
                        <v-row style="background-color: #89b0ae; padding: 10px" align="center" justify="center">
                            <img src="../assets/7_mood.png" style="width:90%;height:auto; align-self: center"/>
                        </v-row>
                        <v-row style="padding: 15px; padding-top: 15px !important;">
                            <ul>
                                <li>Link <strong>emotions</strong> to <strong>body</strong> sensations and <strong>triggers</strong>
                                </li>
                                <li>Select <strong>tab</strong> to filter by mood</li>
                                <li>Click on a <strong>word</strong> to filter by concept</li>
                            </ul>
                        </v-row>
                        <v-row style="background-color: #89b0ae; padding: 10px" align="center" justify="center">
                            <img src="../assets/8_filter.png" style="width:90%;height:auto; align-self: center"/>
                        </v-row>
                        <v-row style="padding: 15px; padding-top: 15px !important;">
                            <ul>
                                <li>Check details in <strong>diary</strong>. <i>Selected filters also apply to diary
                                    page</i></li>
                            </ul>


                        </v-row>
                        <v-row style="background-color: #89b0ae; padding: 10px" align="center" justify="center">
                            <img src="../assets/10_diary.png" style="width:90%;height:auto; align-self: center"/>
                        </v-row>
                        <v-row style="padding: 15px; padding-bottom: 0px !important;">
                            <p><strong>
                                SETTINGS
                            </strong></p>
                        </v-row>
                        <v-row style="padding: 15px; padding-top: 5px !important;">
                            <ul>
                                <li>Select your <strong>language</strong></li>
                                <li>Set a <strong>reminder</strong> for check-in</li>
                                <li>Permanently <strong>delete</strong> your account</li>
                            </ul>
                        </v-row>
                        <v-row style="padding: 15px; padding-bottom: 0px !important;">
                            <p><strong>
                                CONTACT
                            </strong></p>
                        </v-row>
                        <v-row style="padding: 15px; ">
                            <p>pirozhochek.lab@gmail.com</p>
                        </v-row>
                    </v-col>
                </v-row>

            </v-col>
        </v-row>
    </v-container>


</template>


<script>
    import {COLORS} from "../main";

    export default {
        components: {},
        provide: {},
        data() {
            return {
                language: "en",
                screenWidth: window.innerWidth,
                colorDark: COLORS.MAIN,
            };
        },
        beforeCreate() {
        },
        mounted() {
            window.addEventListener('change-language', () => {
                this.language = localStorage.getItem("language");
            });

            if(!localStorage.getItem("language")){
                this.setLanguage(this.language)
            }else{
                this.language = localStorage.getItem("language")
            }

        },
        computed: {},
        created() {
            window.addEventListener("resize", this.handleResize);
        },
        watch: {},
        methods: {
            setLanguage(lng){
                localStorage.setItem("language", lng)
                window.dispatchEvent(new CustomEvent('change-language', {
                }));
            },
            handleResize() {
                this.screenWidth = window.innerWidth
            },
        },
    }
</script>

<style scoped>

</style>