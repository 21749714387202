<template>
    <v-container fluid style="min-height: 90vh" :class="[{ 'desktop': screenWidth>600 }]">
        <v-row class="normal-text">
            <v-col
                    cols="12"
                    :class="[{ 'no-padding': screenWidth<600 }]"
            >
                <v-row>
                    <v-tabs :class="[{ 'no-padding': screenWidth<960 }]"
                            fixed-tabs
                            :background-color="colorDark"
                            :slider-color="colorDark"
                            dark
                            style="padding: 0px !important; justify-content: flex-start; align-content: flex-start"
                    >
                        <v-tab style="padding: 0px !important; margin-left: 5px !important; justify-content: flex-start; justify-self: flex-start"
                               @click="$router.go(-1)"
                        >
                            <v-icon color="white" style="padding-left: 10px; padding-right: 5px;">mdi-arrow-left
                            </v-icon>
                            <span v-if="language=='ru'">НАЗАД</span>
                            <span v-else>BACK</span>
                        </v-tab>

                    </v-tabs>
                </v-row>

                <v-row align-content="center" class="light-background">
                    <v-timeline
                            align-top
                            dense
                    >
                        <v-timeline-item
                                v-for="(item, i) in userData"
                                :key="i"
                                :color="moodIcons[item.mood].color"
                                :icon="moodIcons[item.mood].key"
                                fill-dot
                                small
                        >
                            <v-row style="padding: 0px; margin: 0px">
                                <v-col style="padding: 0px; margin: 0px">
                                    <span style="font-weight: bold; font-size: xx-small; padding: 0px; margin: 0px"
                                    >{{item.date}}</span>
                                </v-col>
                            </v-row>
                            <v-row style="padding: 0px; margin: 0px">

                                <v-col style="padding: 0px; padding-right: 15px !important; margin: 0px">
                                    <span style="font-size: x-small; padding: 0px; margin: 0px"
                                    >{{item.emotions}}</span>
                                    <div>
                                    <span style="font-weight: bold; font-size: x-small; padding: 0px; margin: 0px"
                                    >{{item.reasons}}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-timeline-item>

                    </v-timeline>
                </v-row>
            </v-col>
        </v-row>
    </v-container>


</template>


<script>
    import {COLORS} from "../main";

    export default {
        components: {},
        provide: {},
        data() {
            return {
                language:'en',
                screenWidth: window.innerWidth,
                colorDark: COLORS.MAIN,
                userData: [],
                moodIcons: {
                    "Terrible": {key: "mdi-emoticon-sad-outline", color: COLORS.TERRIBLE},
                    "Bad": {key: "mdi-emoticon-confused-outline", color: COLORS.BAD},
                    "Fine": {key: "mdi-emoticon-neutral-outline", color: COLORS.FINE},
                    "Good": {key: "mdi-emoticon-happy-outline", color: COLORS.GOOD},
                    "Great": {key: "mdi-emoticon-outline", color: COLORS.GREAT},
                    "Ужасно": {key: "mdi-emoticon-sad-outline", color: COLORS.TERRIBLE},
                    "Плохо": {key: "mdi-emoticon-confused-outline", color: COLORS.BAD},
                    "Нормально": {key: "mdi-emoticon-neutral-outline", color: COLORS.FINE},
                    "Хорошо": {key: "mdi-emoticon-happy-outline", color: COLORS.GOOD},
                    "Отлично": {key: "mdi-emoticon-outline", color: COLORS.GREAT}
                },

            };
        },
        beforeCreate() {
        },
        mounted() {
            window.addEventListener('change-language', () => {
                this.language = localStorage.getItem("language");
            });
            if(!localStorage.getItem("language")){
                this.setLanguage(this.language)
            }else{
                this.language = localStorage.getItem("language")
            }
            let data = JSON.parse(localStorage.getItem("userDataSelected"));
            data.reverse()
            data.forEach(item => {
                item.moodEmotions.forEach(me => {
                    let i = {}
                    i["date"] = item.date;
                    i["mood"] = item.mood;
                    i["emotions"] = me.emotions.join(", ").toUpperCase()

                    i["reasons"] = []
                    me.reasonDetails.forEach(reason => {
                        if (reason.toLowerCase() != "skip") {
                            i["reasons"].push(reason)
                        }
                    });
                    i["reasons"] = i["reasons"].join(". ")
                    this.userData.push(i)
                })
            })
        },
        computed: {},
        created() {
            window.addEventListener("resize", this.handleResize);
        },
        watch: {},
        methods: {
            handleResize() {
                this.screenWidth = window.innerWidth
            },
        },
    }
</script>

<style scoped>

</style>